function labelActive() {
	let label = this.nextElementSibling;
	this.classList.toggle('active');
	label.classList.toggle('active');

	if (this.value.length > 0) {
		this.classList.add('filled');
		label.classList.add('filled');
	} else {
		this.classList.remove('filled');
		label.classList.remove('filled');
	}
}

if (document.querySelector('.form-container')) {
	const inputs = document.querySelectorAll('form .form-input');

	inputs.forEach(function (input) {
		input.addEventListener('focus', labelActive);
		input.addEventListener('blur', labelActive);
	});
}