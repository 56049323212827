import { gsap } from 'gsap';

const containers = gsap.utils.toArray('.js-container');

containers.forEach(container => {
    gsap.to(container, {
        scrollTrigger: {
            trigger: container,
            markers: false,
            // start: 'top 75%',
            start: '-10% bottom',
            onEnter: () => {
                container.classList.add('js-animate');
                //console.dir(container.parentElement)
            }
        }
    })
});



setTimeout(function () {
    const cookieButton = document.querySelector('.cookie-box__button');
    const widget = document.querySelector('#widget-button');
    if (widget) {
        if (cookieButton) {
            gsap.to(widget, { x: 0, duration: .3, delay: .2 });

            cookieButton.addEventListener('click', (event) => {
                gsap.to(widget, { top: '80%', duration: .5, delay: .2 });
            });
        } else {
            widget.classList.add('push');
            gsap.to(widget, { x: 0, duration: .3, delay: .2 });
        }
    }
}, 1000);