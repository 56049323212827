import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

// home constants 
const parallaxContainer = document.querySelector('.parallax-container');
const parallaxPattern = document.querySelector('img.parallax');
// team constants
const teamContainer = document.querySelector('section.components .team');
const teamPatternTop = document.querySelector('img.team-card-img.top');
const teamPatternMiddle = document.querySelector('img.team-card-img.middle');
const teamPatternBottom = document.querySelector('img.team-card-img.bottom');


if (teamContainer) {
    const pattern = document.querySelector('section.components .team .team-pattern');
    const teamDots = gsap.utils.toArray('section.components .team .item-team-card-photo-container img.dots');


    gsap.to(pattern, {
        yPercent: -30,
        ease: 'none',
        scrollTrigger: {
            trigger: teamContainer,
            scrub: 0.5,
            start: 'top 70%'
        }
    })

    teamDots.forEach(dot => {
        gsap.to(dot, {
            yPercent: -20,
            duration: 0.2,
            ease: 'none',
            scrollTrigger: {
                trigger: teamContainer,
                scrub: 0.5,
                markers: false,
                start: 'top 70%',
                onEnter: () => {
                    teamContainer.firstElementChild.classList.add('js-animate');
                }
            }
        });
    });

}

if (parallaxContainer && parallaxPattern) {

    const styles = window.getComputedStyle(parallaxPattern, null);
    const evalDisplay = styles.getPropertyValue('display');
    const isVisible = evalDisplay != 'none';


    if (isVisible) {
        gsap.to(parallaxPattern, {
            yPercent: -20,
            ease: 'none',
            duration: 0.2,
            scrollTrigger: {
                trigger: parallaxContainer,
                scrub: 0.5
            }
        })
    }
}

if (document.querySelector('.normal-parallax-trigger')) {
    gsap.to('.normal-parallax', {
        yPercent: -5,
        ease: 'none',
        scrollTrigger: {
            trigger: '.normal-parallax-trigger',
            scrub: true
        }
    });
}

