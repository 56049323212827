import { gsap } from "gsap";
import ScrollTrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

const cardsContainer = document.querySelector('.cards-container');

if (cardsContainer) {
	ScrollTrigger.create({
		trigger: '.cards-container',
		start: 'top 70%',
		markers: false,
		onEnter: () => {
			spreadCardBelow();
			spreadCardAbove();
			$('.cards-container .column.two').addClass('visible');
		}
	})
}

function spreadCardBelow() {
	var cardTimeLine = gsap.timeline();

	if ($(window).width() < 350) {
		cardTimeLine.to('#cardBelow', 0.8, { x: 10, y: 125, rotation: 4 });
		return cardTimeLine;
	} 
	else if ($(window).width() < 768) {
		cardTimeLine.to('#cardBelow', 0.8, { x: 10, y: 200, rotation: 4 });
		return cardTimeLine;
	} 
	else if ($(window).width() < 1025) {
		cardTimeLine.to('#cardBelow', 0.8, { x: 50, y: 150, rotation: 4 });
		return cardTimeLine;
	} 
	else {
		cardTimeLine.to('#cardBelow', 0.8, { x: 80, y: 160, rotation: 4 });
		return cardTimeLine;
	}

};

function spreadCardAbove() {
	var cardTimeLine = gsap.timeline();
	if ($(window).width() < 350) {
		cardTimeLine.to('#cardAbove', 0.8, { x: 0, y: -120, rotation: -10 });
		return cardTimeLine;
	} 
	else if ($(window).width() < 768) {
		cardTimeLine.to('#cardAbove', 0.8, { x: 0, y: -120, rotation: -10 });
		return cardTimeLine;
	} 
	else if ($(window).width() < 1025) {
		cardTimeLine.to('#cardAbove', 0.8, { x: 0, y: -200, rotation: -10 });
		return cardTimeLine;
	} 
	else {
		cardTimeLine.to('#cardAbove', 0.8, { x: -50, y: -200, rotation: -10 });
		return cardTimeLine;
	}

}

