const cardButtons = document.querySelectorAll('article.item-card .item-card-button') ?? false;

function hoverCards() {
    console.log(this)
    let card = this.parentElement;

    card.classList.toggle('active');

}


if (cardButtons) {
    cardButtons.forEach(function (cardButton) {
        cardButton.addEventListener('focus', hoverCards);
        cardButton.addEventListener('blur', hoverCards);
    });
}