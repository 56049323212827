//import "./loopingBanner";
//import "lazysizes";
// import "owl.carousel/dist/assets/owl.carousel.css";
// import "owl.carousel";
import "./fonts";
//import "./slideGallery";
import "./navigation";
import "./item-cards";
//import "./faqs";
import "./collapsible";
//import "./modalLightbox";
import "./animatedCards";
import './menuMobile';
import './forms';
//import './instagramGallery';
import './parallax';
import './onloading';
//import './heroRotate';
import './ios-scroll';
// import './topbar';

