$(function () {
	// open - close collapsible hero
	$('.collapsible-info [class^="trigger-"]').click(function () {
		$('.collapsible-content').slideToggle();
	});
	// open - cloase collapsible hero (onkeypress enter)
	$('.collapsible-info [class^="trigger-"]').on('keypress', function (e) {
		if (e.which == 13) {
			$('.collapsible-content').slideToggle();
			e.preventDefault();
		}
	});

	// open - close collapsible tabs 
	$('.collapsible-item [class^="trigger-"]').click(function () {
		$(this).next('.collapsible-item-content').slideToggle();
		const newLocal = 'icon-collapsed';
		$(this).toggleClass('opened');
		if($(this).hasClass('opened')){
			$(this).attr('aria-expanded', 'false');
		}else{
			$(this).attr('aria-expanded', 'true');
		}
	});

	// open - close collapsible tabs (onkeypress enter)
	$('.collapsible-item [class^="trigger-"]').on('keypress', function (e) {
		if (e.which == 13) {
			$(this).next('.collapsible-item-content').slide();
			$(this).toggleClass('opened');
		}
	});
});