import { gsap } from 'gsap';
const bodyScrollLock = require('body-scroll-lock');
const disableBodyScroll = bodyScrollLock.disableBodyScroll;
const enableBodyScroll = bodyScrollLock.enableBodyScroll;
const widgetButton = document.getElementById('widget-button');
const menuButton = document.getElementById('mobile-menu-button');
const header = document.querySelector('header');
const nav = document.getElementById('main-navigation');
const body = document.querySelector('body');
const navigationContainer = document.getElementById('main-navigation-container');
const navItems = document.querySelectorAll('.list-navigation-item');

function headerChecker() {
    let vw = document.documentElement.clientWidth;
    let isDesktop = vw > 787;

    if (isDesktop && header) {
        if (body.classList.value.includes('menu-opened')) {
            body.classList.remove('menu-opened');
        }
        if (header.classList.value.includes('opened')) {
            header.classList.remove('opened');
        }
        if (nav.classList.value.includes('opened')) {
            nav.classList.remove('opened');
        }
        if (menuButton.classList.value.includes('opened')) {
            menuButton.classList.remove('opened');
        }
    }
}

window.addEventListener('resize', headerChecker);
window.addEventListener('load', headerChecker);


function hideWidget() {
    gsap.fromTo(widgetButton, { x: 0, alpha: 1 }, {
        x: '100%', duration: 0.2, alpha: 0, onComplete: () => {
            widgetButton.style.visibility = 'hidden';
            showMenuMobile();
        }
    });
}

function showWidget() {
    gsap.fromTo(widgetButton, { x: '100%', alpha: 0 }, {
        x: 0, alpha: 1, duration: 0.2, ease: "power2.out", onStart: () => {
            widgetButton.style.visibility = 'visible';
        }
    });
}

function showMenuMobile() {
    gsap.fromTo(navigationContainer, { y: '-150%', visibility: 'hidden' }, {
        y: 0, duration: 1, ease: "power2.out", delay: 0.02, visibility: 'visible', onStart: () => {
            //navigationContainer.classList.add('reset-transition-delay');
            nav.classList.add('opened');
        }
    });
}



function hiddeMenuMobile() {
    gsap.fromTo(navigationContainer, { y: '0', visibility: 'visible' }, {
        y: '-150%', ease: "power2.out", duration: 2, onStart: () => {
            nav.classList.remove('opened');
            //navigationContainer.classList.remove('reset-transition-delay');
        }, onComplete: () => {
            navigationContainer.style.visibility = 'hidden';
            //nav.classList.remove('opened');
            //showWidget();
        }
    });
}


if (menuButton) {
    menuButton.addEventListener('click', (event) => {
        header.classList.toggle('opened');
        menuButton.classList.toggle('opened');
        if (menuButton.classList.value.includes('opened')) {
            disableBodyScroll(navigationContainer);
            document.querySelector('#focus-helper').setAttribute('tabindex', "0");
        } else {
            enableBodyScroll(navigationContainer);
            document.querySelector('#focus-helper').setAttribute('tabindex', "-1");
        }
        menuButton.classList.toggle('is-active');
        nav.classList.toggle('opened');
        body.classList.toggle('menu-opened');
        document.querySelector('html').classList.toggle('menu-opened');
        menuButton.setAttribute('aria-expanded', menuButton.classList.value.includes('opened'));
    });
}