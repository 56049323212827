function dropdownMenus() {
    let container = this.parentElement;
    container.classList.toggle('open');
    this.setAttribute('aria-expanded', container.classList.value.includes('open'));
    let isOpen = container.classList.value.includes('open');
    if (isOpen) {
        //dropdownHelper(container, this.nextElementSibling);
        container.querySelector('.dropdown-helper').setAttribute('tabindex', 0);
        container.querySelector('.dropdown-helper').addEventListener('focus', helperFunctions)
    } else {
        container.querySelector('.dropdown-helper').setAttribute('tabindex', -1);
    }


    closeDropdown(this);

}

// function dropdownHelper(dropdownContainer, dropdownChildren) {
//     let children = Array.from(dropdownChildren.children);
//     let lastIndexOnBlur = children.length - 1;
//     console.log(dropdownContainer)
//     console.log(children.length)
//     children.forEach(function (child, index) {
//         child.firstElementChild.addEventListener('blur', () => {
//             //console.log(index)
//             if (lastIndexOnBlur === index) {
//                 console.log(child)
//             }
//         })
//     });
// }

function helperFunctions(e) {
    let previousFocusedElement = e.relatedTarget;
    let allLinks = Array.from(document.links);
    allLinks.forEach(function (link, index) {
        let dropdownContainer = link.parentElement.parentElement.parentElement;
        let linkFounded = link === previousFocusedElement;
        if (linkFounded) {
            let nextLink = allLinks[index + 1];
            let nextLinkQuery = '.' + allLinks[index + 1].className.replace(' ', '.');
            let NotDropdownChild = dropdownContainer.querySelector(nextLinkQuery) === null ? true : false;

            if (NotDropdownChild) {
                dropdownContainer.classList.remove('open');
                dropdownContainer.firstElementChild.setAttribute('aria-expanded', false);
                dropdownContainer.lastElementChild.setAttribute('tabindex', -1);
                nextLink.focus();
            }
        }
    })
}

function closeDropdown(dropdownButton) {
    window.addEventListener('click', (event) => {
        if (dropdownButton != event.target) {
            if (dropdownButton.parentElement.classList.value.includes('open')) {
                dropdownButton.parentElement.classList.remove('open');
                dropdownButton.setAttribute('aria-expanded', false);
            }
        }
    });
}

if (document.querySelector('.dropdown-navigation-item')) {
    const dropdownButtons = document.querySelectorAll('.dropdown-navigation-item');

    dropdownButtons.forEach(function (dropdownButton) {

        let dropdownContainer = dropdownButton.nextElementSibling;
        dropdownButton.addEventListener('click', dropdownMenus);
        dropdownButton.addEventListener('blur', (e) => {
            let isOpen = dropdownButton.getAttribute('aria-expanded') === 'true';
            //console.log(dropdownButton)
            if (isOpen) {
                let nextElementContainer = e.relatedTarget ? e.relatedTarget.parentElement.parentElement : false;
                let nextFocusedIsNotChild = nextElementContainer !== dropdownContainer;
                if (nextFocusedIsNotChild) {
                    dropdownButton.parentElement.classList.remove('open');
                    dropdownButton.setAttribute('aria-expanded', false);
                    dropdownButton.parentElement.lastElementChild.setAttribute('tabindex', -1);
                }
            }
        });
    });

}

if (document.querySelector('#focus-helper')) {
    document.querySelector('#focus-helper').addEventListener('focus', (event) => {
        if (document.querySelector('#mobile-menu-button').classList.value.includes('is-active')) {
            document.querySelector('#mobile-menu-button').focus();
        } else {
            document.querySelectorAll('.dropdown-navigation-item').forEach(function (dropdownButton) {
                let isOpen = dropdownButton.getAttribute('aria-expanded') === 'true';
                console.log(isOpen)
            });
            if (document.querySelector('#widget-button')) {
                document.querySelector('#widget-button').focus();
            } else {
                document.querySelector('#focus-helper').removeAttribute('tabindex');
            }
        }

    });
}

// document.addEventListener('keydown', (e) => {
//     let currentFocus = document.activeElement;
//     let dropdownSibling = currentFocus.classList.contains('dropdown-navigation-item') ? currentFocus : false;
//     //console.dir(currentFocus);
//     //console.log(currentFocus.parentElement.parentElement.classList.contains('dropdown-container'))
//     if (currentFocus.parentElement.parentElement.classList.contains('dropdown-container')) {
//         console.log(document.activeElement)
//     }
// })